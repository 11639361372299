import { useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Context as Web3Context } from "../../contexts/web3";
import TokenIcon from "../../icons/TokenIcon";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Collateral } from "../../api/getCollaterals/types";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { InlineDottyProgress } from "../../components/DottyProgress";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import IconTypography from "../../components/IconTypography";
import InfoIcon from "../../components/InfoIcon";
import { useTranslation } from "react-i18next";

export default function Erc20CollateralRow({
  collateral,
  onCollateralClick,
}: {
  collateral: Collateral;
  onCollateralClick: (collateral: Collateral) => void;
}): JSX.Element {
  const symbol = collateral.symbol as TokenSymbol;
  const { walletProvider, account } = useContext(Web3Context);
  const {
    data: balance,
    isLoading: isBalanceLoading,
    status: balanceStatus,
  } = useUserErc20Balance({
    token: collateral,
    walletProvider,
    account,
  });

  const { t } = useTranslation();

  const balanceFloat = parseFloat(balance || "0");

  return (
    <TableRow
      style={{ cursor: "pointer" }}
      hover={!!account}
      onClick={() => {
        if (account) {
          onCollateralClick(collateral);
        }
      }}
    >
      <TableCell align="left">
        <IconTypography variant="body1" color="textSecondary">
          <TokenIcon style={{ marginRight: ".5em" }} symbol={symbol} />
          {symbol}

          {symbol === "VWMBp" && (
            <Tooltip
              arrow
              placement="right"
              title={
                <>
                  {t("tooltips.collateral.VWMBp")}{" "}
                  <Link
                    color="common.white"
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    href="https://blog.hifi.finance/introducing-our-first-real-world-asset-2395668f5aaf"
                  >
                    {t("Learn More")}
                  </Link>
                  .
                </>
              }
            >
              <InfoIcon tabIndex={0} />
            </Tooltip>
          )}
          {symbol === "SHEETp" && (
            <Tooltip
              arrow
              placement="right"
              title={
                <>
                  {t("tooltips.collateral.SHEETp")}{" "}
                  <Link
                    color="common.white"
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    href="https://blog.hifi.finance/sheet-heads-borrowing-live-c89d59fc91b0"
                  >
                    {t("Learn More")}
                  </Link>
                  .
                </>
              }
            >
              <InfoIcon tabIndex={0} />
            </Tooltip>
          )}
          {symbol === "CRE1p" && (
            <Tooltip
              arrow
              placement="right"
              title={
                <>
                  {t("tooltips.collateral.CRE1p")}{" "}
                  <Link
                    color="common.white"
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    href="https://blog.hifi.finance/real-estate-as-collateral-77e60814aaa1"
                  >
                    {t("Learn More")}
                  </Link>
                  .
                </>
              }
            >
              <InfoIcon tabIndex={0} />
            </Tooltip>
          )}
          {symbol === "HC1" && (
            <Tooltip
              arrow
              placement="right"
              title={
                <>
                  {t("tooltips.collateral.HC1")}{" "}
                  <Link
                    color="common.white"
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    href="https://blog.hifi.finance/collateral-ruleset-professionally-managed-racehorses-45871b4771bc"
                  >
                    {t("Learn More")}
                  </Link>
                  .
                </>
              }
            >
              <InfoIcon tabIndex={0} />
            </Tooltip>
          )}
          {symbol === "RCC1" && (
            <Tooltip
              arrow
              placement="right"
              title={
                <>
                  {t("tooltips.collateral.RCC1")}{" "}
                  <Link
                    color="common.white"
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    href="https://blog.hifi.finance/collateral-ruleset-residential-construction-3126c35ce4e9"
                  >
                    {t("Learn More")}
                  </Link>
                  .
                </>
              }
            >
              <InfoIcon tabIndex={0} />
            </Tooltip>
          )}
          {symbol === "MEC1" && (
            <Tooltip
              arrow
              placement="right"
              title={
                <>
                  {t("tooltips.collateral.MEC1")}{" "}
                  <Link
                    color="common.white"
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    href="https://blog.hifi.finance/collateral-ruleset-medical-equipment-1cf3d1c404e7"
                  >
                    {t("Learn More")}
                  </Link>
                  .
                </>
              }
            >
              <InfoIcon tabIndex={0} />
            </Tooltip>
          )}
          {symbol === "VVC1" && (
            <Tooltip
              arrow
              placement="right"
              title={
                <>
                  {t("tooltips.collateral.VVC1")}{" "}
                  <Link
                    color="common.white"
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    href="https://blog.hifi.finance/hip-12-collateral-ruleset-vintage-vehicles-b09870fb8aaa"
                  >
                    {t("Learn More")}
                  </Link>
                  .
                </>
              }
            >
              <InfoIcon tabIndex={0} />
            </Tooltip>
          )}
          {symbol === "CRE2" && (
            <Tooltip
              arrow
              placement="right"
              title={
                <>
                  {t("tooltips.collateral.CRE2")}{" "}
                  <Link
                    color="common.white"
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    href="https://blog.hifi.finance/hip-13-commercial-real-estate-collateral-ruleset-7c3402cf61c3"
                  >
                    {t("Learn More")}
                  </Link>
                  .
                </>
              }
            >
              <InfoIcon tabIndex={0} />
            </Tooltip>
          )}
        </IconTypography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {isBalanceLoading && <InlineDottyProgress />}
          {balanceStatus === "success" &&
            balance &&
            // balanceFloat > 0 &&
            `${numberWithCommas(truncateDecimals(balance, 4))} ${balanceFloat > 0 ? collateral.symbol : ""}`}
          {!account && <span>&ndash;</span>}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">{Math.round(parseFloat(collateral.collateralizationRatio) * 100)}%</Typography>
      </TableCell>
    </TableRow>
  );
}
