import SvgIcon from "@mui/material/SvgIcon";

export default function VVCI({ style }: { style?: React.CSSProperties }): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 65 65" style={style}>
      <circle cx="32" cy="32" r="32" fill="#101010" />
      <path
        d="M48.2708 48.4028H47.4921V17.0065C47.4921 15.6803 46.4174 14.6035 45.0891 14.6035H32.0155C30.6873 14.6035 29.6106 15.6803 29.6106 17.0065V35.8938C27.8828 36.0276 26.5486 37.4694 26.5465 39.2032V42.547C26.5567 42.9972 26.2444 43.3906 25.8023 43.4818C25.5428 43.5265 25.2751 43.4535 25.0744 43.2831C24.8716 43.1128 24.756 42.8613 24.754 42.5977V27.0994C25.703 27.0933 26.4674 26.3227 26.4674 25.3757V23.0559L28.7001 21.6871C28.917 21.5553 28.9839 21.2735 28.8521 21.0565L28.1789 19.9574C28.1161 19.854 28.0126 19.779 27.895 19.7506C27.7774 19.7222 27.6517 19.7425 27.5483 19.8053L23.4257 22.332C22.8823 22.6666 22.5497 23.2587 22.5477 23.8975V42.4902C22.5254 44.0759 23.6751 45.4345 25.2426 45.6739C26.1288 45.7915 27.0231 45.5197 27.6943 44.9317C28.3675 44.3416 28.7528 43.492 28.7548 42.5977V39.2051C28.7548 38.6881 29.1097 38.2399 29.6126 38.1203V48.4036H28.8339V48.4056C28.1789 48.4056 27.6497 48.9349 27.6497 49.5878C27.6497 50.2408 28.1789 50.7701 28.8339 50.7701H48.2702C48.9232 50.7701 49.4524 50.2408 49.4524 49.5878C49.4524 48.9349 48.9232 48.4056 48.2702 48.4056L48.2708 48.4028ZM32.7279 19.9767C32.7279 19.3664 33.2227 18.8715 33.8331 18.8715H43.2728C43.8832 18.8715 44.378 19.3664 44.378 19.9767V26.9952C44.378 27.6056 43.8832 28.1004 43.2728 28.1004H33.8331C33.2227 28.1004 32.7279 27.6056 32.7259 26.9952L32.7279 19.9767Z"
        fill="white"
        transform="translate(-4, 0)"
      />
    </SvgIcon>
  );
}
